html {
    background-color: #181a1b !important;
}

body {
  margin: 0;
    background-color: #181a1b;
    color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.customNavbar {
    background-color: #00bbff !important;
    font-weight: 600;
}

.navbarLink {
    font-weight: 600;
    margin-left: 1em;
    color: white !important;
}

.navbarSelected {
    font-weight: 600;
    color: black !important;
}

.tmtExperimentSelector {
    border: 2px solid #00bbff;
    text-align: center;
    height: 2.5em;
    padding-top: 0.3em;
    vertical-align: middle;
    margin-top: 3em;
    box-shadow: 3px 3px 3px #0080e2;
}

.tmtExperimentSelector:hover {
     border: 2px solid #00bbff;
     text-align: center;
     height: 2.5em;
     padding-top: 0.3em;
     vertical-align: middle;
     margin-top: 3em;
     top: 1px;
     box-shadow: 1px 1px 3px #0080e2;
     cursor: pointer;
 }

.tmtExperimentSelector:active {
    border: 2px solid #3000ff;
    text-align: center;
    height: 2.5em;
    padding-top: 0.3em;
    vertical-align: middle;
    margin-top: 3em;
    top: 1px;
    box-shadow: 1px 1px 3px #0080e2;
    cursor: pointer;
}

.simpleLink, .simpleLink:hover {
    text-decoration: inherit;
    color: inherit
}

.vRowSpacer {
    margin-top: 1em;
}

.submitButton {
    text-align: center;
    border: 2px solid #00bbff;
}

.submitButton:hover {
    text-align: center;
    border: 2px solid #00bbff;
    background-color: #00bbff;
}

.submitButton:active {
    text-align: center;
    border: 2px solid #00bbff;
    background-color: #bbebff;
}

.resetButton {
    text-align: center;
    border: 2px solid #ffbc00;
}

.resetButton:hover {
    text-align: center;
    border: 2px solid #ffbc00;
    background-color: #ffbc00;
}

.resetButton:active {
    text-align: center;
    border: 2px solid #ffbc00;
    background-color: #ffbc00;
}

.createExperimentButton {
    text-align: center;
    border: 2px solid #00ffb2
}

.createExperimentButton:hover {
    text-align: center;
    border: 2px solid #00ffb2;
    background-color: #00ffb2;
}

.createExperimentButton:active {
    text-align: center;
    border: 2px solid #00ffb2;
    background-color: #00ffb2;
}

.form-control:focus{
     border-color: #00bbff;
     box-shadow: none;
     -webkit-box-shadow: none;
 }

.custom-select:focus{
    border-color: #00bbff;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.errorMessage {
    color: #ff003a;
    font-size: 0.85em;
    font-style: italic;
}

.jsonDownloadButton {
    margin-top: 0.15em;
    margin-right: 0.25em;
    height: 45px;
    width: 45px;
    cursor: pointer;
    text-align: right;
    vertical-align: center;
}

.jsonDownloadButton:active {
    height: 45px;
    width: 43px;
    top: 2px;
    margin-left: 2px;
}

.csvDownloadButton {
    height: 18px;
    width: 18px;
    cursor: pointer;
    text-align: right;
}

.csvDownloadButton:active {
    height: 16px;
    width: 16px;
    top: 2px;
}

.newColumnButton {
    height: 15px;
    width: 15px;
    cursor: pointer;
    text-align: right;
}

.newColumnButton:active {
    height: 13px;
    width: 13px;
    top: 2px;
}

.input-group > div > span, .input-group > div > label {
    background-color: #23292b;
    border-color: #4b5659;
    color: white;
}

.form-control, .custom-select, .form-control:disabled, .custom-select:disabled {
    background-color: #181a1b;
    border-color: #4b5659;
    color: white;
}

.form-control:focus, .custom-select:focus {
    background-color: #181a1b;
    border-color: #00bbff;
    color: white;
}

.infoButton {
    position: fixed;
    cursor: pointer;
    height: 20px;
    width: 20px;
    text-align: right;
}

.infoButton:active {
    position: fixed;
    cursor: pointer;
    height: 18px;
    width: 18px;
}

.staticInfoButton {
    cursor: pointer;
    height: 20px;
    width: 20px;
    text-align: right;
    vertical-align: top;
}

.staticInfoButton:active {
    cursor: pointer;
    height: 18px;
    width: 18px;
    vertical-align: top;
}

.tmtOptionsPopup {
    position: fixed;
    top: 50%;
    z-index: 15;
    background-color: #313537;
    border: 2px solid #00bbff;
    box-shadow: 20px 30px 10px #1f1f1f;
}

.tmtOptionBinary {
    z-index: 10;
}

.binaryYesNotSelected {
    cursor: pointer;
    color: white;
}

.binaryNoNotSelected {
    cursor: pointer;
    color: white;
}

.binaryYesSelected {
    cursor: pointer;
    color: #00ffb2;
}

.binaryNoSelected {
    cursor: pointer;
    color: #ffb200;
}

.plexGroup {
    border: 2px solid #00bbff;
}

.channelRow { }

.holder {
    cursor: pointer;
}

.channelLabel {
    overflow-x: auto;
    border: 1px solid dodgerblue;
}

.channelSample {
    overflow-x: auto;
    margin-left: 3px;
    border: 1px dotted deeppink;
    white-space: pre-wrap;
}

.plexTitle {
    margin-top: 0.5em;
    margin-bottom:0.65em;
}

.notificationBox {
    position: fixed;
    border: 1px solid #00bbff;
}

.channelColorCalTrig {
    color: #00ffb2;
}

.channelColorVarAssess {
    color: #ff9c00;
}

.optionsBox {}

.dropbox {
    height: 10em;
    border: 2px solid #a7a7a7;
}

#padSampleNotification {
    display: block;
}

#infoButtonCheck {
    display: block;
}

#infoButtonX {
    display: none;
}

#tmtSelectionOptionsGroup {
    display: none;
}

/* Loader */
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #00bbff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #00bbff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* End Loader */

.table {
    color: white;
}